import React from 'react'

const RequirementsTab = () => {
    return (
        <div className="tab-pane fade show active" id="nav-requirements" role="tabpanel" aria-labelledby="nav-requirements-tab">
            <div className="requirementsContent">
                <h5>New Students</h5>
                <ul>
                    <li>Original Form 138-A (High School Report Card);</li>
                    <li>Original or Certified Copy of Birth Certificate/PSA;</li>
                    <li>Certified Copy of Alien Certificate of Registration (ACR), if alien student;</li>
                    <li>Certificate of Recommendation from the NHSAFI Admissions Officer;</li>
                    <li>Accomplished Student’s Personal Data Sheet; and</li>
                    <li>Certificate of Good Moral Character with school seal from the school last attended.</li>
                </ul>
                <h5 className="pt-5">Transferee</h5>
                <ul>
                    <li>Certificate of Transfer Credential (also called Honorable Dismissal);</li>
                    <li>Informative copy of Transcript of Records;</li>
                    <li>A statement of good moral character signed by the College Dean or Head of Student Affairs or Head of the previous school;</li>
                    <li>Original or certified copy of Birth Certificate;</li>
                    <li>Certified copy of Alien Certificate of Registration (ACR), if foreign student;</li>
                    <li>Interview Clearance from the Office of Student Affairs;</li>
                    <li>Clearance from the Foreign Students Coordinator, if foreign student;</li>
                    <li>Certificate of Recommendation from the Admissions Officer;</li>
                    <li>Accomplished Student’s Personal Data Sheet; and</li>
                    <li>Accomplished Accreditation Form.</li>
                </ul>
            </div>
        </div>
    )
}

export default RequirementsTab
