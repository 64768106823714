import React from 'react'

const AboutLanding = () => {
    return (
        <div id="aboutLanding">
            <h1>ABOUT NEW HOPE</h1>
        </div>
    )
}

export default AboutLanding
