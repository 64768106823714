import React from 'react'
import Footerimg from '../../Assets/Image/subfooter-logo.png'
import Footerimg1 from '../../Assets/Image/subfooter-ipadpro-logo.png'
import Footerarrowup from '../../Assets/Image/footer-arrow-up.png'
import Facebook from '../../Assets/Image/footer-facebook.png'
import Instagram from '../../Assets/Image/footer-instagram.png'
import Twitter from '../../Assets/Image/footer-twitter.png'

const Subfooter = () => {
    return (
        <div id="Subfooter-id">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-md-12">
                        <img src={Footerimg} className="d-none d-xl-block" alt=""/>
                        <img src={Footerimg1} className="d-none d-md-block d-xl-none" alt=""/>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <h1>Links</h1>
                        <p>About us</p>
                        <p>Programs</p>     
                        <p>Admission</p>
                        <div className="bottom-padding">
                            <p>Privacy Policy</p>
                            <p>Terms and Condition</p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <h1>Contact</h1>
                        <p>(083) 000-2853</p>
                        <p>newhope@newhope.com.ph</p>
                        <p>Cannery Road, Valencia Site, Polomolok, South Cotabato</p>
                    </div>

                    <div className="d-block d-xl-none">
                        <div className="footer-icon">
                            <img src={Facebook} className="img-fluid padding-right" alt="" />
                            <img src={Instagram} className="img-fluid padding-right" alt="" />
                            <img src={Twitter} className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <a href="#top">
                            <img src={Footerarrowup} alt=""/>
                        </a>
                    </div>
                    {/* d-none col-xl-6 d-xl-block */}
                    {/* <div className="d-block d-xl-none">
                        <div className="footer-icon">
                            <img src={Facebook} className="img-fluid padding-right" alt="" />
                            <img src={Instagram} className="img-fluid padding-right" alt="" />
                            <img src={Twitter} className="img-fluid" alt="" />
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
    )
}

export default Subfooter
