import React from 'react'
import {useState} from 'react'
// import {useLocation} from 'react-router-dom'
import Headerlogo from '../../Assets/Image/header-logo.png';
import Mobilelogo from '../../Assets/Image/mobile_nav_logo.png';
import MobileNavIcon from '../../Assets/Image/mobile_nav_icon.png';

import { NavHashLink } from 'react-router-hash-link';

const Navigation = ({scrollUp}) => {
    // for scrolling
    const [paddingChange, setPaddingChange] = useState(true)
    window.addEventListener('scroll', () => {
        if (window.scrollY >= 100) {
            setPaddingChange(false)
        } else {
            setPaddingChange(true)
        } ;
    })

    return (
        <div id="Navigation-id" className={`${!paddingChange && 'activePadding'}`}>
            <nav className="navbar navbar-expand-md">
                <NavHashLink className="navbar-brand" exact to="/" onClick={scrollUp}>
                    <img src={Headerlogo} alt="" className="d-none d-md-block" />
                    <img src={Mobilelogo} alt="" className="d-md-none"/>
                </NavHashLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    {/* <span className="navbar-toggler-icon"></span> */}
                    <img src={MobileNavIcon} alt="" className="d-md-none"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav #ml-auto" onClick={scrollUp}>
                        <li className="nav-item active">
                            <NavHashLink className="nav-link" exact to="/" activeClassName="main-nav-active">HOME</NavHashLink>
                        </li>
                        <li className="nav-item">
                            <NavHashLink className="nav-link" exact to="/programs" activeClassName="main-nav-active">PROGRAMS</NavHashLink>
                        </li>
                        <li className="nav-item">
                            <NavHashLink className="nav-link" exact to="/admission" activeClassName="main-nav-active">ADMISSION</NavHashLink>
                        </li>
                        <li className="nav-item">
                            <NavHashLink className="nav-link" exact to="/about" activeClassName="main-nav-active">ABOUT US</NavHashLink>
                        </li>
                        <li className="nav-item">
                            <NavHashLink className="nav-link" exact to="/contact" activeClassName="main-nav-active">CONTACT US</NavHashLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navigation
