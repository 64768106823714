import React from 'react'

const Carouseltext = () => {
    return (
        <div id="Carouseltext-id">
             <div className="container-fluid">
                 <div className="row">
                     <div className="col-lg-12">
                         <div>
                            <div className="carousel-title">
                                <h1>Find your course thats</h1>
                                <h1>makes your <span><div className="line-bottom"></div>bright</span>  FUTURE.</h1>
                            </div>
                            <div className="carousel-paragraph">
                                <p className="d-none d-md-block">“Dream high, Break through, secure your future.</p>
                                <p className="d-none d-md-block">With new hope, everything is achievable.”</p>
                                
                                <p className="d-md-none">
                                    “Dream high, Break through, secure your future.With new hope, everything is achievable.”
                                </p>
                            </div>
                            <div className="carousel-btn">
                                <button className="btn btn-enroll">ENROLL NOW</button>
                                <button className="btn btn-course">VIEW COURSES OFFERED</button>
                            </div>
                        </div>
                     </div>
                 </div>
             </div>
        </div>
    )
}

export default Carouseltext
