import Carousel from './Carousel'
import Newhopetext from './Newhopetext'
import Newhopevector from './Newhopevector'
import Whatsnew from './Whatsnew'

const Home = () => {
    return (
        <div id="Home-id">
            <Carousel />
            <Newhopetext />
            <Newhopevector />
            <Whatsnew />
        </div>
    )
}
export default Home
