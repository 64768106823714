import React from 'react'

const Newhopetext = () => {
    return (
        <div id="Newhopetext-id">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            <h1>NEW HOPE is not just a school but a family</h1>
                            <h1>where we support each other.</h1>
                            <p>
                            The New Hope School of Fisheries and Agriculture rises to alleviate the inadequacy of higher education institution particularly in the field of fisheries and agriculture in municipality of Polomolok and its surroundings in the province of South Cotabato. The school aims to provide education to those willing and deserving individuals who wanted to take the said academic programs but unfortunately unable to enroll themselves to college due to inaccessibility and remoteness. In accordance to globalization, the school sought the right programs to be offered that will meet the demands of the current trend domestically and internationally.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newhopetext
