import React from 'react'
import Subfooter from './Subfooter'
import Mainfooter from './Mainfooter'

const Footer = () => {
    return (
        <div>
            <Subfooter />
            <Mainfooter />
        </div>
    )
}

export default Footer
