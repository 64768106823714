import React from 'react'
import Images from '../../Assets/Image'

const AboutContent = () => {
    return (
        <div id="aboutContent" className="container">
            <section className="goal">
                <h1>GOAL</h1>
                <h2>GOAL</h2>
                <p><strong>New Hope</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Error maiores laborum excepturi voluptatem, aut debitis repudiandae nostrum eum quibusdam, veniam adipisci similique voluptatum pariatur sequi eaque. Culpa sapiente mollitia magnam?</p>
            </section>


            <section className="mission">
                <div className="row">
                    <div className="col-lg-5 text-right imgContainer d-none d-lg-block">
                        <img src={Images.Mission} alt="Mission"/>
                    </div>
                    <div className="col-lg-7 textContainer">
                        <div className="mission-content">
                            <h1>MISSION</h1>
                            <h2>MISSION</h2>
                            <p className="aboutPara">
                                To provide contemporary and relevant quality education towards technological advancement, global competence, and multidisciplinary development for its stakeholders and interested parties.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 text-right imgContainer d-md-block d-lg-none">
                        {/* <img src={Images.Mission} alt="Mission"/> */}
                    </div>
                </div>
            </section>

            {/* Vission */}
            {/* Vission desktop, ipad pro */}
            <section className="vision d-none d-lg-block">
                <div className="row">
                    <div className="col-lg-7 textContainer">
                        <div className="vision-content">
                            <h1>VISION</h1>
                            <h2>VISION</h2>
                            <p className="aboutPara">
                                A premier learning institution dedicated to produce industry-ready and holistically developed professionals.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 text-right imgContainer">
                        <img src={Images.Vision} alt="Vision"/>
                    </div>
                </div>
            </section>

            {/* Vission ipad */}
            <section className="mission d-lg-none">
                <div className="row">
                    <div className="col-lg-5 text-right imgContainer d-none d-lg-block">
                        <img src={Images.Mission} alt="Mission"/>
                    </div>
                    <div className="col-lg-7 textContainer">
                        <div className="mission-content">
                        <h1>VISION</h1>
                            <h2>VISION</h2>
                            <p className="aboutPara">
                                A premier learning institution dedicated to produce industry-ready and holistically developed professionals.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 text-right imgContainer d-md-block d-lg-none">
                        {/* <img src={Images.Mission} alt="Mission"/> */}
                    </div>
                </div>
            </section>



            {/* core values Desktop - ipad pro */}
            <section className="coreValues d-none d-lg-block">
                <div className="row">
                    <div className="col-lg-5 text-right imgContainer">
                        <img src={Images.CoreValues} alt="CoreValues"/>
                    </div>
                    <div className="col-lg-7 textContainer">
                        <div className="core-content">
                            <h1>CORE VALUES</h1>
                            <h2>CORE VALUES</h2>
                            <ul className="aboutPara">
                                <li>I-Innovation</li>
                                <li>H-Honor</li>
                                <li>O-Optimism</li>
                                <li>P-Professionalism</li>
                                <li>E-Excellence</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* core values ipad */}
            <section className="mission d-lg-none">
                <div className="row">
                    <div className="col-lg-5 text-right imgContainer d-none d-lg-block">
                        <img src={Images.Mission} alt="Mission"/>
                    </div>
                    <div className="col-lg-7 textContainer">
                        <div className="mission-content">
                            <h1>CORE VALUES</h1>
                            <h2>CORE</h2>
                            <ul className="aboutPara">
                                <li>I-Innovation</li>
                                <li>H-Honor</li>
                                <li>O-Optimism</li>
                                <li>P-Professionalism</li>
                                <li>E-Excellence</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 text-right imgContainer d-md-block d-lg-none">
                        {/* <img src={Images.Mission} alt="Mission"/> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutContent
