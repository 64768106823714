import React from 'react'
import News1 from '../../Assets/Image/news-img1.png'
import News2 from '../../Assets/Image/news-img2.png'
import News3 from '../../Assets/Image/news-img3.png'

const Whatsnew = () => {
    return (
        <div id="Whatsnew-id">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>WHATS NEW</h1>
                    </div>
                </div>
                <div className="row">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-lg-5 text-right">
                                        <img src={News1} alt=""/>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="news-content">
                                            <h2>Why do we use Lorem Ipsum?</h2>
                                            <h3>13 JULY 2021</h3>
                                            <p>
                                                Lorem Ipsu m is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the
                                                industry's standard dummy text ever since the 1500s.
                                                {/* when an unknown printer took a galley of type and
                                                scrambled it to make a type specimen book.
                                                It has survived not only five centuries, but also the leap into
                                                electronic typesetting, remaining essentially
                                                unchanged. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-lg-5 text-right">
                                        <img src={News2} alt=""/>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="news-content">
                                            <h2>Covid-19 Updates</h2>
                                            <h3>14 JULY 2021</h3>
                                            <p>
                                                Lorem Ipsu m is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the
                                                industry's standard dummy text ever since the 1500s.
                                                {/* when an unknown printer took a galley of type and
                                                scrambled it to make a type specimen book.
                                                It has survived not only five centuries, but also the leap into
                                                electronic typesetting, remaining essentially
                                                unchanged. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-lg-5 text-right">
                                        <img src={News3} alt=""/>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="news-content">
                                            <h2>All students are invited to join padyak activities. </h2>
                                            <h3>15 JULY 2021</h3>
                                            <p>
                                                Lorem Ipsu m is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the
                                                industry's standard dummy text ever since the 1500s.
                                                {/* when an unknown printer took a galley of type and
                                                scrambled it to make a type specimen book.
                                                It has survived not only five centuries, but also the leap into
                                                electronic typesetting, remaining essentially
                                                unchanged. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whatsnew
