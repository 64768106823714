import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import Navigation from './Components/Navigation/Navigation';
import Home from './Components/Homepage/Home';
import Program from './Components/Programpage/Program';
import Admission from './Components/Admissionpage/Admission';
import About from './Components/Aboutpage/About';
import Conatact from './Components/Contactpage/Contact';
import Footer from './Components/Footer/Footer';


function App() {

  const scrollUp = () => {
    window.scrollTo(0,0)
  }

  return (
    <Router>
      <Navigation scrollUp={scrollUp}/>
      <Route exact path='/' component={Home} />
      <Route exact path='/programs' component={Program} />
      <Route exact path='/admission' component={Admission} />
      <Route exact path='/about' component={About} />
      <Route exact path='/contact' component={Conatact} />
      <Footer />
    </Router>
  );
}

export default App;
