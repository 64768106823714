
const Programlanding = () => {
    return (
        <div id="programLanding">
            <h1>PROGRAMS OFFERED</h1>
        </div>
    )
}


export default Programlanding
