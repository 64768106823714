import React from 'react'
import Images from '../../Assets/Image'

const ProcedureTab = () => {
    return (
        <div className="tab-pane fade" id="nav-procedures" role="tabpanel" aria-labelledby="nav-procedures-tab">
            <div className="proceduresContent">
                <img src={Images.Procedure} alt="Procedures" className="img-fluid"/>
            </div>
        </div>
    )
}

export default ProcedureTab
