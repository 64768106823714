import React from 'react'
import Programlanding from './Programlanding'
import ProgramLists from './ProgramLists'

const Program = () => {
    return (
        <section id="program">
            <Programlanding />
            <ProgramLists />
        </section>
    )
}

export default Program
