import React from 'react'

const ContactLanding = () => {
    return (
        <div id="contactLanding">
            <h1>LET'S GET IN TOUCH</h1>
        </div>
    )
}

export default ContactLanding
