import React from 'react'
import Images from '../../Assets/Image'

const ContactContent = () => {
    return (
        <div id="contactContent" className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="row infoContent">
                        <div className="col-12">
                            <h1>MESSAGE US.</h1>
                            <h2 className="message">MESSAGE</h2>
                            <h2 className="us d-none d-md-block">US</h2>
                            <div className="detailsContainer">
                                <div className="iconContainer">
                                    <img src={Images.LandlineIcon} alt="LandlineIcon" />
                                    <p>(083) 000-0000</p>
                                </div>
                                <div className="iconContainer">
                                    <img src={Images.PhoneIcon} alt="PhoneIcon" />
                                    <p>0906 875 8643</p>
                                </div>
                                <div className="iconContainer">
                                    <img src={Images.MailIcon} alt="MailIcon" />
                                    <p>newhope@newhope.com.ph</p>
                                </div>
                                <div className="iconContainer">
                                    <img src={Images.LocationIcon} alt="LocationIcon" />
                                    <p>Cannery Road, Valencia Site, South Cotabato</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h1>FOLLOW US</h1>
                            <div className="socmedIcons">
                                <img src={Images.Facebook} alt="Facebook" />
                                <img src={Images.Instagram} alt="Instagram" />
                                <img src={Images.Twitter} alt="Twitter" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.6031348880015!2d125.01276878622203!3d6.315749087832568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f78f32596713a5%3A0xf38301ab5eed9dd1!2sBarangay%20Kablon%20Rd%2C%20Tupi%2C%20South%20Cotabato!5e0!3m2!1sen!2sph!4v1632984166136!5m2!1sen!2sph" width="100%" height="100%" style={{border:'0', borderRadius: '1em'}} allowFullScreen={true} loading="lazy" title="NewHopeMap"></iframe>
                </div>
            </div>
        </div>
    )
}

export default ContactContent
