import React from 'react'
import PolicyTab from './PolicyTab'
import ProcedureTab from './ProcedureTab'
import RequirementsTab from './RequirementsTab'

const AdmissionContent = () => {
    return (
        <div id="admissionContent" className="container">
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="nav-link active" id="nav-requirements-tab" data-toggle="tab" href="#nav-requirements" role="tab" aria-controls="nav-requirements" aria-selected="true">REQUIREMENTS</a>
                    <a className="nav-link" id="nav-procedures-tab" data-toggle="tab" href="#nav-procedures" role="tab" aria-controls="nav-procedures" aria-selected="false">PROCEDURES</a>
                    <a className="nav-link" id="nav-policy-tab" data-toggle="tab" href="#nav-policy" role="tab" aria-controls="nav-policy" aria-selected="false">POLICY</a>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <RequirementsTab />
                <ProcedureTab />
                <PolicyTab />
            </div>
        </div>
    )
}

export default AdmissionContent
