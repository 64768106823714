import React from 'react'
import Facebook from '../../Assets/Image/footer-facebook.png'
import Instagram from '../../Assets/Image/footer-instagram.png'
import Twitter from '../../Assets/Image/footer-twitter.png'

const Mainfooter = () => {
    return (
        <div id="Mainfooter-id">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <p className="d-none d-md-block">© 2021 | Classify Inc. www. Classify.com.ph | All rights reserved</p>

                        <p className="d-block d-md-none">© 2021</p>
                        <p className="d-block d-md-none">Classify Inc. www. Classify.com.ph</p>
                        <p className="d-block d-md-none">All rights reserved</p>
                    </div>
                    <div className="d-none col-xl-6 d-xl-block">
                        <div className="footer-icon">
                            <img src={Facebook} className="img-fluid padding-right" alt="" />
                            <img src={Instagram} className="img-fluid padding-right" alt="" />
                            <img src={Twitter} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mainfooter
