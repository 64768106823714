const Images = {
    BSAgriculture : require('./BSAgriculture.png').default,
    BSAnimalScience : require('./BSAnimalScience.png').default,
    BSInformationSystem : require('./BSInformationSystem.png').default,
    BSFisheries : require('./BSFisheries.png').default,
    BSInternalAuditing : require('./BSInternalAuditing.png').default,
    BSAccounting : require('./BSAccounting.png').default,
    BSOfficeAdministration : require('./BSOfficeAdministration.png').default,
    BSCriminology : require('./BSCriminology.png').default,
    BSChildhoodEduc : require('./BSChildhoodEduc.png').default,
    Mission : require('./Mission.png').default,
    Vision : require('./Vision.png').default,
    CoreValues : require('./CoreValues.png').default,
    LandlineIcon : require('./LandlineIcon.png').default,
    PhoneIcon : require('./PhoneIcon.png').default,
    MailIcon : require('./MailIcon.png').default,
    LocationIcon : require('./LocationIcon.png').default,
    Facebook : require('./footer-facebook.png').default,
    Instagram : require('./footer-instagram.png').default,
    Twitter : require('./footer-twitter.png').default,
    Procedure : require('./Procedure.png').default,
}

export default Images