import React from 'react'
import AboutLanding from './AboutLanding'
import AboutContent from './AboutContent'

const About = () => {
    return (
        <section id="about">
            <AboutLanding />
            <AboutContent />
        </section>
    )
}

export default About
