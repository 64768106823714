import React from 'react'
import Carousel1 from '../../Assets/Image/home-carousel1.png'
import Carousel2 from '../../Assets/Image/home-carousel2.png'
import Carousel3 from '../../Assets/Image/home-carousel3.png'
import ipadCarousel1 from '../../Assets/Image/home-ipadpro-carousel1.png'
import ipadCarousel2 from '../../Assets/Image/home-ipadpro-carousel2.png'
import ipadCarousel3 from '../../Assets/Image/home-ipadpro-carousel3.png'
import MobileCarousel1 from '../../Assets/Image/home-mobile-carousel1.png'
import MobileCarousel2 from '../../Assets/Image/home-mobile-carousel2.png'
import MobileCarousel3 from '../../Assets/Image/home-mobile-carousel3.png'
import Arrowdown from '../../Assets/Image/home-arrow- down.png'
import Carouseltext from './Carouseltext'

const Carousel = () => {

    return (
        <div id="Carousel-id">
            <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Carousel1} className="w-100 d-none d-xl-block" alt="..."/>
                        <img src={ipadCarousel1} className="w-100 d-none d-md-block d-xl-none" alt="..."/>
                        <img src={MobileCarousel1} className="w-100 d-md-none" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src={Carousel2} className="w-100 d-none d-xl-block" alt="..." />
                        <img src={ipadCarousel2} className="w-100 d-none d-md-block d-xl-none" alt="..."/>
                        <img src={MobileCarousel2} className="w-100 d-md-none" alt="..."/>
                    </div>
                    <div className="carousel-item">
                        <img src={Carousel3} className="w-100 d-none d-xl-block" alt="..."/>
                        <img src={ipadCarousel3} className="w-100 d-none d-md-block d-xl-none" alt="..."/>
                        <img src={MobileCarousel3} className="w-100 d-md-none" alt="..."/>
                    </div>

                    <div id="Carousel-content">
                        <Carouseltext />
                    </div>

                </div>
            </div>

            {/* Arrow Down */}
            <div id="arrowAnimation">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <section className="arrow-down text-right">
                                <a href="/#Newhopetext-id">
                                    <img src={Arrowdown} className="img-fluid" alt="Arrow Effect" />
                                </a>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/* ---------- */}
        </div>
    )
}

export default Carousel
