import React from 'react'
import ContactLanding from './ContactLanding'
import ContactContent from './ContactContent'

const Contact = () => {
    return (
        <section id="contact">
            <ContactLanding />
            <ContactContent />
        </section>
    )
}

export default Contact
