import React from 'react'

const AdmissionLanding = () => {
    return (
        <div id="admissionLanding">
            <h1>ADMISSION INFORMATION</h1>
        </div>
    )
}

export default AdmissionLanding
