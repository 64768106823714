import React from 'react'

const PolicyTab = () => {
    return (
        <div className="tab-pane fade" id="nav-policy" role="tabpanel" aria-labelledby="nav-policy-tab">
            This is Policy Tab
        </div>
    )
}

export default PolicyTab
