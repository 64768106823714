import React from 'react'
import AdmissionContent from './AdmissionContent'
import AdmissionLanding from './AdmissionLanding'

const Admission = () => {
    return (
        <section id="admission">
            <AdmissionLanding />
            <AdmissionContent />
        </section>
    )
}

export default Admission
