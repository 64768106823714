import Images from "../../Assets/Image"
import ProgramCard from "./ProgramCard"


const ProgramLists = () => {

    const ProgramList = [
        {
            course : 'Bachelor of Science in Agriculture',// Major in Animal Science
            description : "It emphasizes the process and techniques of identifying, diagnosing and analyzing problems and in designing, packaging and applying technologies need in the development and conservation of agriculture and food system resources. .",
            image : Images.BSAnimalScience
        },
        {
            course : 'Bachelor of Agricultural Technology',
            description : "The program provides both theoretical and practical aspects of growing crops and raising animals. Areas of application include soil management and conservation, animal breeding and production, crop production, pest management, post-harvest technology and farm business management.",
            image : Images.BSAgriculture
        },
        {
            course : 'Bachelor of Science in Information System',
            description : "The program will provide students with foundation knowledge on organizations that allows them to understand how Information and Communication Technology can enable industries to improve performance and achieve competitive advantage..",
            image : Images.BSInformationSystem
        },
        {
            course : 'Bachelor of Science in Fisheries',
            description : "The BS in Fisheries and Aquatic Resources students provides students with a strong scientific background on inland fisheries, marine fisheries and fish processing. It includes topics in natural resources management, interactions of the physics, chemistry, and biology of aquatic ecosystems, and online mapping applications..",
            image : Images.BSFisheries
        },
        {
            course : 'Bachelor of Science in Internal Auditing',
            description : "The BSIA program should provide a foundation of professional knowledge, professional skills, and professional values, ethics and attitudes that enable them to continue to learn and adapt to change throughout their professional lives in internal auditing..",
            image : Images.BSInternalAuditing
        },
        {
            course : 'Bachelor of Science in Accounting Information System',
            description : "As described in the CMO, accounting information system professionals link operational activities, financial resource generation and consumption, value generation, and preservation through a well-functioning information system..",
            image : Images.BSAccounting
        },
        {
            course : 'Bachelor of Science in Office Administration',
            description : "Is a program designed to provide students with knowledge and skills in business and office management needed in various workplaces such as general business, legal, or medical offices. The program prepares students to be able to handle clerical, administrative, supervisory and managerial tasks..",
            image : Images.BSOfficeAdministration
        },
        {
            course : 'Bachelor of Science in Criminology',
            description : "The program teaches students the various theories, policies, practices, and laws associated with criminal behavior and the methods applied to manage deviant activities..",
            image : Images.BSCriminology
        },
        {
            course : 'Bachelor of Science in Early Childhood Education',
            description : "is a program prepares student for both the art and science of teaching children 0 – 8 years old. The program culminates with an extensive teaching internship in various cooperating schools, both public and private, local and international.",
            image : Images.BSChildhoodEduc
        },
    ]

    const Programs = ProgramList.map((program, index) => <ProgramCard key={index} program={program} />)

    return (
        <div id="programList" className="container">
            <div className="row">
                {Programs}
            </div>
        </div>
    )
}

export default ProgramLists
