import React from 'react'
import Affordable from '../../Assets/Image/vector-ae.png'
import Offers from '../../Assets/Image/vector-ovsp.png'
import Commitment from '../../Assets/Image/vector-cee.png'
import Highly from '../../Assets/Image/vector-hqcp.png'

const Newhopevector = () => {
    return (
        <div id="Newhopevector-id">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-deck">
                            <div className="card">
                                <img src={Affordable} className="card-img-top img-fluid img-width" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Affordable education
                                    </h5>
                                </div>
                            </div>
                            <div className="card">
                                <img src={Offers} className="card-img-top img-fluid" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Offers various
                                        scholarship programs
                                    </h5>
                                </div>
                            </div>
                            <div className="card">
                                <img src={Commitment} className="card-img-top img-fluid" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Commitment to excellent education
                                    </h5>
                                </div>
                            </div>
                            <div className="card">
                                <img src={Highly} className="card-img-top img-fluid" alt="..."/>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Highly qualified and competitive professors
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newhopevector
