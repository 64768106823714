
const ProgramCard = ({program}) => {
    return (
        <div className="col-lg-4 cardContainer">
            <div className="card h-100">
                <img src={program.image} className="card-img-top" alt={program.course}/>
                <div className="card-body">
                    <h5 className="card-title">{program.course}</h5>
                    <p className="card-text">{program.description}</p>
                </div>
            </div>
        </div>
    )
}

export default ProgramCard
